import React, { useEffect } from 'react';
import Header from "../components/Header";
import { useUser } from "../contexts/UserContext";
import { Helmet } from 'react-helmet';


const Layout = ({ children }) => {

    const { getValidJwtToken, darkMode } = useUser();
    const flowwiseUrl = process.env.REACT_APP_FLOWISE_URL || 'http://localhost:3000';
    const flowwiseChatFlowId = process.env.REACT_APP_FLOWISE_CHAT_FLOW_ID || '9b0a4f23-2eee-4a46-bf9b-434100d49522';

    useEffect(() => {
        const token = getValidJwtToken();
        if (token) {
            const script = document.createElement('script');
            script.type = 'module';
            script.src = '/js/web.js';

            script.onload = () => {
                if (window.Chatbot) {
                    window.Chatbot.init({
                        chatflowid: flowwiseChatFlowId,
                        apiHost: flowwiseUrl,
                    });
                }
            };

            document.body.appendChild(script);

            return () => {
                // Cleanup: remove the script when the component is unmounted
                document.body.removeChild(script);
            };
        }
    }, [getValidJwtToken]);

    return (
        <>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
            </Helmet>
            <div className={darkMode ? "dark-mode" : ""}>
                <Header />
                {children}
            </div>
        </>
    );
};

export default Layout;
