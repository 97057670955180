import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchStrategyByID } from '../services/api';
import { Container, Form } from 'react-bootstrap';
import { useUser } from '../contexts/UserContext';
import { PriceChart } from '../components/PriceChart';
import { parseData } from '../utils/dataProcessing';
import Loader from '../components/Loader';

const ItemDetail = () => {
  const { id } = useParams();
  const { getValidJwtToken, setToken, darkMode } = useUser();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [checkedAlgorithms, setCheckedAlgorithms] = useState({});

  const primarySource = item ? item.sources.find(source => source.primary === true) : null;

  const fetchItem = useCallback(async () => {
    const token = getValidJwtToken();
    const { data, responseCode } = await fetchStrategyByID(id, token);
    if (responseCode === 500) {
      setToken(null);
      navigate('/');
    } else {
      setItem(data);
    }
  }, [id, getValidJwtToken, setToken, navigate]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  useEffect(() => {
    if (item) {
      const allAlgorithms = item.sources.flatMap(source => source.algorithms);
      const initialCheckedState = allAlgorithms.reduce((acc, alg) => {
        acc[alg.name] = true;
        return acc;
      }, {});
      setCheckedAlgorithms(initialCheckedState);

      const activeAlgorithms = allAlgorithms.map(alg => alg.name);
      const parsedData = parseData(item, darkMode, activeAlgorithms);
      setParsedData(parsedData);
    }
  }, [item, darkMode]);

  const handleCheckboxChange = (algorithmName) => {
    const newCheckedState = {
      ...checkedAlgorithms,
      [algorithmName]: !checkedAlgorithms[algorithmName]
    };
    setCheckedAlgorithms(newCheckedState);

    const activeAlgorithms = Object.keys(newCheckedState).filter(name => newCheckedState[name]);
    const parsedData = parseData(item, darkMode, activeAlgorithms);
    setParsedData(parsedData);
  };

  if (!item || !parsedData) {
    return <Loader />;
  }

  return (
    <div className={darkMode ? 'dark-mode' : ''} style={{ height: '200vh' }}>
      <Container className="mt-5">
        <div className={darkMode ? 'chart-container dark' : 'chart-container'}>
          <h3 className="mb-3">
            {item.name}
            {primarySource && (
              <small className="text-muted ms-2">
                {primarySource.ticker} - {primarySource.interval}
              </small>
            )}
          </h3>
          <div className="algorithm-checkboxes mt-3">
            <h5>Algorithms</h5>
            <Form>
              {item.sources.flatMap(source => source.algorithms).map((algorithm, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  id={`checkbox-${algorithm.name}`}
                  label={algorithm.name}
                  checked={checkedAlgorithms[algorithm.name]}
                  onChange={() => handleCheckboxChange(algorithm.name)}
                />
              ))}
            </Form>
          </div>
          <PriceChart initialData={parsedData} />
        </div>
      </Container>
    </div>
  );
};

export default ItemDetail;
