import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useUser } from '../contexts/UserContext';
import { Link } from 'react-router-dom';

const Home = () => {
    const { getValidJwtToken } = useUser();
    const token = getValidJwtToken();

    const containerStyle = {
        height: '90vh', // Full viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column', // Stack items vertically
        textAlign: 'center'
    };

    const imageStyle = {
        width: '30%',
        height: '80%',
    };

    return (
        <Container style={containerStyle}>
            <Row>
                <Col>
                    <img src="/images/aztech-corp-full.png" alt="Logo" style={imageStyle} />
                    <h2>Go to <Link to="/strategylist">Strategies</Link> to view the indicators....</h2>
                </Col>

            </Row>
        </Container>
    );
};

export default Home;
